import { RemoveRedEye } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { LinearProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { castToType } from "../utils/misc";
import EyeOff from "./icons/eyeOff";
import { useField } from "formik";

const CustomInput = (props) => {
  const [type, setType] = useState(props.type);
  const handleInputChangeCasted = (event, typeLocal) => {

    let typeCast = typeLocal;
    if (type === "number") {
      typeCast = "number";
      // event.target.value < 0 ? (event.target.value = 0) : event.target.value;
    }

    let { name, value } = event.target;

    const e = {
      target: {
        name: name,
        value: castToType(value, typeCast),
        checked: undefined,
      },
    };

    props.onChange(e);
  };

  const Adornment = () => (
    <>
      {props.value !== null && props.value !== '' && (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear field"
            onClick={() => {
              props.onChange({ target: { name: props.name, value: "" } });
            }}
            size="small"
            edge="end"
          >
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      )}
      {props.type === "password" && (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear field"
            onClick={() => {
              if (type === "password" && props.type === "password") {
                setType("text");
              } else if (props.type === "password" && type === "text") {
                setType("password");
              }
            }}
            edge="end"
            size="large"
          >
            {props.type === "password" && type === "password" && <RemoveRedEye />}
            {props.type === "password" && type === "text" && <EyeOff />}
          </IconButton>
        </InputAdornment>
      )}
    </>
  );

  return (
    <div style={{ position: "relative" }}>
      <TextField
        {...props}
        InputLabelProps={{ shrink: props.shrink }}
        label={props.label}
        placeholder={props.placeholder}
        inputProps={{
          "data-test": props.name,
          min: props.min,
          max: props.max,
        }}
        name={props.name}
        id={props.id || `${props.name}-label`}
        value={props.value}
        type={type}
        disabled={Boolean(props.disabled)}
        onChange={(e) => {
          handleInputChangeCasted(e, props.propType);
        }}
        classes={props.classes}
        error={props.error}
        helperText={props.helperText || ' '}
        fullWidth
        size={props.size}
        variant={props.variant || "standard"}
        multiline={props.multiline}
        InputProps={{
          ...(props.clearFieldIcon || props.type === "password"
            ? {
                autoComplete: props.autoComplete,
                startAdornment: props.loading && (
                  <LinearProgress
                    style={{
                      width: "100%",
                      position: "absolute",
                      bottom: 0,
                      height: 2,
                      zIndex: 1,
                    }}
                  />
                ),
                endAdornment: <Adornment />,
                ...props.inputProps,
              }
            : {
                ...props.inputProps,
              }),
        }}
      ></TextField>
    </div>
  );
};

export default CustomInput;
