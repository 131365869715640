import { gql, useApolloClient, useMutation } from "@apollo/client";
import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { msg } from "../../../../messages";
import CommonModal from "../../../CommonModal";
import CustomAutocomplete from "../../../CustomAutocomplete";

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_PROPERTIES_BY_OBJECT = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      objectProperties(orderBy: GROUP_NAME_ASC) {
        id
        spec {
          typeId
          description
          property
          groupName
        }
      }
    }
  }
`;

const modes = [
  {
    value: "sum",
    title: "Sum",
  },
  {
    value: "first",
    title: "First value",
  },
  {
    value: "last",
    title: "Last value",
  },
  {
    value: "average",
    title: "Average",
  },
  {
    value: "median",
    title: "Median",
  },
  {
    value: "min",
    title: "Min",
  },
  {
    value: "max",
    title: "Max",
  },
];

// Do not show "sum" and "average" is property is of type "string"
const modesPropertyIsString = [
  {
    value: "first",
    title: "First value",
  },
  {
    value: "last",
    title: "Last value",
  },
];

const stringTypeId = "00000000-0000-0000-0000-000000000001";

const StaticTableColumnsModal = ({
  isEdit,
  parameterObject,
  condition,
  conditionIndex,
  onResolve,
  onReject,
  isOpen,
  columns,
  mode,
}) => {
  const client = useApolloClient();
  const [property, setProperty] = useState(null);
  const [localMode, setMode] = useState(null);
  const [properties, setPropertiesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [updateProperty, { loading: updatingProperty }] =
    useMutation(UPDATE_PROPERTY);

  const submit = () => onResolve();

  const reject = () => onReject();

  const getProperties = async () => {
    const objectId = parameterObject.value;
    if (objectId) {
      setLoading(true);
      try {
        const result = await client.query({
          query: GET_PROPERTIES_BY_OBJECT,
          variables: {
            objectId,
          },
          fetchPolicy: "network-only",
        });

        setPropertiesList(
          result.data.object.objectProperties.map((item) => {
            return {
              value: item.id,
              title: `${item.spec.groupName} / ${item.spec.description}`,
              typeId: item.spec.typeId === stringTypeId ? "string" : "",
            };
          })
        );

        if (isEdit) {
          setProperty(condition);
          setMode(mode);
        }
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getProperties();
  }, [columns]);

  const handleEditProperty = (value) => {
    updateProperty({
      variables: {
        input: {
          id: columns.id,
          patch: {
            value,
          },
        },
      },
    }).then(() => {
      submit();
    });
  };

  return (
    <CommonModal
      key="ConditionModal"
      modalOpen={isOpen}
      title={isEdit ? "Edit column" : "Add column"}
      handleClose={reject}
      buttons={
        <>
          <Button onClick={reject}>{msg.default.cancel}</Button>
          <Button
            color="primary"
            disabled={!property?.value || !localMode}
            onClick={async () => {
              const oldValues = cloneDeep(columns.value.columns);

              if (isEdit) {
                oldValues.splice(conditionIndex, 1, {
                  mode: localMode,
                  value: {
                    value: property.value,
                    title: property.title,
                  },
                });
                const patch = {
                  ...columns.value,
                  columns: [...oldValues],
                };
                handleEditProperty(patch);
              } else {
                oldValues.push({
                  mode: localMode,
                  value: {
                    value: property.value,
                    title: property.title,
                  },
                });
                const patch = {
                  ...columns.value,
                  columns: [...oldValues],
                };
                handleEditProperty(patch);
              }
            }}
          >
            {updatingProperty ? (
              <CircularProgress size={23} />
            ) : isEdit ? (
              msg.default.save
            ) : (
              msg.default.add
            )}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <CustomAutocomplete
            name="schemaProperties"
            label="Property"
            list={properties}
            disabled={loading}
            value={property?.value || ""}
            onChange={(e) => {
              setProperty(e.target.rawValue);
            }}
          />
        </Grid>
        <Grid item>
          <CustomAutocomplete
            name="mode"
            label="Mode of calculation"
            list={property?.typeId === "string" ? modesPropertyIsString : modes}
            value={localMode}
            onChange={(e) => {
              setMode(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(StaticTableColumnsModal);
