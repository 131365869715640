import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { loader } from "graphql.macro";
import { useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import CommonModal from "../components/CommonModal";
import CustomInput from "../components/CustomInput";
import FormField from "../components/FormField";
import { msg } from "../messages";
import { DASHBOARDS_PROPS_QUERY } from "../queries";
import { setSettings } from "../store/settingsSlice";
import CustomAutocomplete from "./CustomAutocomplete";
import useCustomNavigate from "./hooks/useCustomNavigate";

const DASHBOARDS_QUERY = loader("../graphql/DashboardsQuery.graphql");
const COLLECTIONS_QUERY = loader("../modules/collections/api/CollectionsQuery.graphql");
const COLLECTIONS_QUERY_FULL = loader("../graphql/CollectionsQuery.graphql");
const LINK_OBJECTS = loader("../graphql/LinkObjects.graphql");
const ADD_DASHBOARD = loader("../graphql/AddDashboard.graphql");

const LOAD_GROUP = gql`
  query loadGroups {
    userGroups {
      id
      isSystem
      groupName
      description
    }
  }
`;

const DASHBOARDS_QUERY_WITHOUT_COLLECTIONS = gql(/* GraphQL */ `
  query getDashboardsWithoutCollections {
    dashboards: objects(
      filter: {
        schemaTags: { contains: ["application", "board", "dashboard"] }
        objectsToObjectsByObject1IdConnection: {
          every: { object2: { not: { schemaTags: { contains: ["collection"] } } } }
        }
      }
      orderBy: NAME_ASC
    ) {
      id
      name
    }
  }
`);

const AddDashboardModal = (props) => {
  const history = useCustomNavigate();
  const [linkObjects] = useMutation(LINK_OBJECTS);
  const [addDashboard] = useMutation(ADD_DASHBOARD);
  const collectionsQuery = useQuery(COLLECTIONS_QUERY);
  const groupsQuery = useQuery(LOAD_GROUP);
  const [dashboardsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY, {
    fetchPolicy: "network-only",
  });
  const [dashboardsWithoutCollectionsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: "network-only",
  });
  const [collectionsLazyQuery] = useLazyQuery(COLLECTIONS_QUERY_FULL, {
    fetchPolicy: "network-only",
  });

  const [name, setName] = useState("");

  let defaultValues = {};

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  const validationSchema = yup.object({
    name: yup.string().trim().required("Name is required"),
  });

  const formik = useFormik({
    intitialValues: {
      name,
    },
    validationSchema,
    onSubmit: () => {
      handleAddDashboard();
    },
  });

  const dashboardsQueryProps = useQuery(DASHBOARDS_PROPS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const localValues = {};
      setFields(data.schemata[0].schemaProperties);

      data.schemata[0].schemaProperties.forEach((prop) => {
        localValues[prop.key] = prop.defaultValue;
      });

      const dashBoardsAmount = data.schemata[0].objectsCount;
      const addingZeroCondition = dashBoardsAmount <= 8 ? "0" : "";

      setValues(localValues);
      formik.setValues({
        name: `Dashboard #${addingZeroCondition}${dashBoardsAmount + 1}`,
      });
      setName(`Dashboard #${addingZeroCondition}${dashBoardsAmount + 1}`);
    },
  });

  const [accessRights, setAccessRights] = useState({
    readerGroup: "",
    userGroup: "",
    editorGroup: "",
  });
  const [collection, setCollection] = useState("");

  const [description, setDescription] = useState("");

  const [fields, setFields] = useState([]);

  const getCollections = () => {
    if (!collectionsQuery.loading && collectionsQuery.data) {
      return collectionsQuery.data.collections.map((item) => ({
        value: item.id,
        title: item.name,
      }));
    }
    return [];
  };

  const getGroups = () => {
    if (!groupsQuery.loading && groupsQuery.data) {
      return groupsQuery.data.userGroups.map((item) => ({
        value: item.id,
        title: item.groupName,
        disabled: item.groupName === "Nobody",
      }));
    }
    return [];
  };

  const afterCreate = (id) => {
    history(`/boards/${id}`);
    dispatch(setSettings({ isEditMode: true }));
    submit();
  };

  const handleAddDashboard = () => {
    const payload = {
      values: Object.keys(values).map((key) => {
        return { propertyKey: key, value: values[key] };
      }),
      name: formik.values.name,
      description,
    };

    if (accessRights.editorGroup) {
      payload.editorGroup = accessRights.editorGroup;
    }

    if (accessRights.readerGroup) {
      payload.readerGroup = accessRights.readerGroup;
    }

    if (accessRights.userGroup) {
      payload.userGroup = accessRights.userGroup;
    }

    toast
      .promise(
        addDashboard({
          variables: payload,
        }),
        {
          loading: "Creating dashboard...",
          success: () => "Dashboard created",
          error: (err) => `${err.toString()}`,
        }
      )
      .then(async ({ data }) => {
        if (collection) {
          const variables = {
            widgetId: data.createObjectWithProperties.uuid,
            objectId: collection,
          };

          await linkObjects({ variables });
          await collectionsLazyQuery();
          await dashboardsLazyQuery();
          afterCreate(data.createObjectWithProperties.uuid);
        } else {
          await dashboardsLazyQuery();
          await dashboardsWithoutCollectionsLazyQuery();
          afterCreate(data.createObjectWithProperties.uuid);
        }
      });
  };

  const dispatch = useDispatch();

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked) value = checked;

    setValues({ [name]: value });
  };

  return (
    <>
      <CommonModal
        loading={dashboardsQueryProps.loading}
        modalOpen={props.isOpen}
        title={msg.addDashboardModal.addBoard}
        forceTitle={true}
        contentStyles={{
          padding: "14px 16px 16px 14px",
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>{msg.addDashboardModal.buttonCancel}</Button>
            <Button color="primary" data-test="createBoard" onClick={formik.handleSubmit}>
              {msg.addDashboardModal.buttonAdd}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              shrink={Boolean(formik.values?.name)}
              name="name"
              label={msg.addDashboardModal.name}
              clearFieldIcon={true}
              value={formik.values?.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={collectionsQuery.loading}
              label={"Collection"}
              list={getCollections()}
              value={collection}
              onChange={(e) => {
                setCollection(e.target.value);
              }}
              clearFieldIcon={true}
            />
          </Grid>

          {fields
            .filter((item) => item.groupName === "General" && !item.hidden)
            .map((field) => (
              <FormField key={field.key} values={values} field={field} handleInputChange={handleInputChange} />
            ))}

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Access rights
            </Typography>
          </Grid>

          <Grid item>
            <CustomAutocomplete
              label={"Editors group"}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.editorGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  editorGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={groupsQuery.loading}
              label={"Users group"}
              list={getGroups()}
              value={accessRights.userGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  userGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={"Readers group"}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.readerGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  readerGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Description
            </Typography>
          </Grid>

          <Grid item>
            <CustomInput
              name="description"
              label={msg.addDashboardModal.description}
              clearFieldIcon={true}
              value={description}
              multiline={true}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(AddDashboardModal);
