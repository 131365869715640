import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { loader } from "graphql.macro";
import { cloneDeep, isEqual } from "lodash";
import { cloneElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DASHBOARD_STREAM_DATA_TIME } from "../../constants";
import useRoute from "../../hooks/useRoute";
import useCustomNavigate from "../hooks/useCustomNavigate";

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      controlExecution {
        params
        name
        nodeId
        linkedControlId
        objectId
        id
      }
      clientMutationId
    }
  }
`;

const sideBarWidth = 412;

const useStyles = makeStyles((theme, type) => ({
  screenModeButton: {
    margin: 0,
    right: 16,
    bottom: 16,
    position: "fixed",
    color: theme.palette.white,
  },
  sideBarPaper: {
    width: sideBarWidth,
    flexShrink: 0,
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    backgroundColor: theme.palette.white,
  },
}));

const DASHBOARD_QUERY = loader("../../graphql/DashboardQuery.graphql");
const DASHBOARD_SUBSCRIBE = loader("../../graphql/DashboardSubscribe.graphql");

const SideListWrapper = (props) => {
  const { dashboardId, reportId } = useParams();
  const entityId = dashboardId || reportId;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [createExecution, { loading }] = useMutation(CREATE_EXECUTION);

  const [timeToExpireBoard, setTimeToExpire] = useState(null);
  const [canMakeRequest, setIntervalDelay] = useState(true);
  const { getTypeByRoute } = useRoute();
  const classes = useStyles(getTypeByRoute());
  const isFullScreen = useSelector((state) => state.settings.isFullScreen);
  const isViewMode = useSelector((state) => state.settings.isViewMode);
  const isSideBar = useSelector((state) => state.settings.isSideBar);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [
    load,
    { data: dashboardData, loading: dashboardLoading, subscribeToMore },
  ] = useLazyQuery(DASHBOARD_QUERY);
  const history = useCustomNavigate();
  const client = useApolloClient();

  const [trackedIds, setTrackedIds] = useState([]);

  useEffect(() => {
    if (entityId) {
      load({
        variables: {
          dashboardId: entityId,
        },
      })
        .then((result) => {
          if (!result.data?.dashboard) {
            return history("/boards");
          }

          const getWidgetsIds = () => {
            let arr = [];
            result.data?.dashboard?.groups?.map(({ group }) => {
              group.widgets.forEach(({ widget }) => arr.push(widget.id));
            });
            return arr;
          };

          const getGroupIds = () => {
            if (!result.data?.dashboard?.groups) {
              return [];
            }
            return result.data?.dashboard?.groups?.map(({ group }) => group.id);
          };

          setTrackedIds((prev) => {
            if (
              isEqual(prev, [...getGroupIds(), ...getWidgetsIds(), entityId])
            ) {
              return prev;
            } else {
              return [...getGroupIds(), ...getWidgetsIds(), entityId];
            }
          });
        })
        .catch(() => {
          history("/boards");
        });
    }
  }, [entityId]);

  const continueStreaming = () => {
    if (!canMakeRequest) {
      return false;
    }

    setIntervalDelay(false);

    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "StreamDataToDashboard",
            objectId: entityId,
            params: {
              initialize_data: false,
              stream_data_for: DASHBOARD_STREAM_DATA_TIME,
            },
          },
        },
      },
    }).finally(() => {
      setIntervalDelay(true);
    });
  };

  useEffect(() => {
    if (entityId) {
      const unsubscribe = subscribeToMore({
        document: DASHBOARD_SUBSCRIBE,
        variables: {
          filterA: { id: trackedIds },
          dashboardId: entityId,
        },
        updateQuery: async (previousResult, { subscriptionData }) => {
          const relatedNode = subscriptionData.data?.Objects?.relatedNode;
          const eventType = subscriptionData.data?.Objects?.event;

          if (relatedNode?.key === "dataRefreshStreaming") {
            continueStreaming();
          }

          const data = client.readQuery({
            query: DASHBOARD_QUERY,
            variables: {
              dashboardId: entityId,
            },
          });

          if (eventType === "insert") {
            if (
              relatedNode.object1.schemaTags.includes("group") &&
              relatedNode.object2.schemaTags.includes("widget")
            ) {
              const clonedGroups = cloneDeep(data?.dashboard?.groups || []);
              clonedGroups
                .find(({ group }) => group.id === relatedNode.object1Id)
                .group.widgets.push({
                  widget: relatedNode.widget,
                  id: relatedNode.id,
                  __typename: relatedNode.__typename,
                });

              client.writeQuery({
                query: DASHBOARD_QUERY,
                data: {
                  dashboard: {
                    ...data.dashboard,
                    groups: clonedGroups,
                  },
                },
                variables: {
                  dashboardId: entityId,
                },
              });
            } else if (
              relatedNode.object1.schemaTags.includes("board") &&
              relatedNode.object2.schemaTags.includes("group")
            ) {
              const getGroupIds = () => {
                if (!data?.dashboard?.groups) {
                  return [];
                }
                return data.dashboard.groups?.map(({ group }) => group.id);
              };

              setTimeout(() => {
                setTrackedIds([
                  ...getGroupIds(),
                  relatedNode.group.id,
                  entityId,
                ]);
              }, 200);

              client.writeQuery({
                query: DASHBOARD_QUERY,
                data: {
                  dashboard: {
                    ...data.dashboard,
                    groups: [
                      ...data.dashboard.groups,
                      {
                        group: relatedNode.group,
                        id: relatedNode.id,
                        __typename: relatedNode.__typename,
                      },
                    ],
                  },
                },
                variables: {
                  dashboardId: entityId,
                },
              });
            }
          }
        },
      });

      return () => unsubscribe();
    }
  }, [trackedIds, entityId]);

  return (
    <>
      {isSm && !isFullScreen && (
        <Drawer
          variant="persistent"
          anchor="left"
          data-card-panel={isFullScreen}
          open={
            (props.desktop && !isFullScreen) || (!props.desktop && isSideBar)
          }
          classes={{
            paper: classes.sideBarPaper,
            docked: classes.docked,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {cloneElement(props.children, {
            dashboardData,
            dashboardLoading,
          })}
        </Drawer>
      )}
      {!isSm && (
        <Drawer
          data-card-panel={isFullScreen}
          variant="persistent"
          anchor="left"
          open={
            isViewMode
              ? false
              : (props.desktop && !isFullScreen) ||
                (!props.desktop && isSideBar)
          }
          classes={{
            paper: classes.sideBarPaper,
            docked: classes.docked,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {cloneElement(props.children, {
            dashboardData,
            dashboardLoading,
          })}
        </Drawer>
      )}
    </>
  );
};

export default SideListWrapper;
