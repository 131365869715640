import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import { msg } from "../../../messages";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import ListItemText from "@mui/material/ListItemText";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import React from "react";

const AccessSection = ({ classes, item }) => {
  return (
    <>
      <ListSubheader color="primary" className={classes.listSubheader}>
        <Typography variant="subtitle2">{msg.sideCard.access}</Typography>
      </ListSubheader>

      <ListItem>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText
          primary={item.userGroupByEditorgroup.groupName}
        />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <PlayCircleOutlineIcon />
        </ListItemIcon>
        <ListItemText
          primary={item.userGroupByUsergroup.groupName}
        />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <RemoveRedEyeIcon />
        </ListItemIcon>
        <ListItemText
          primary={item.userGroupByReadergroup.groupName}
        />
      </ListItem>
    </>);
};

export default AccessSection;
