import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@mui/material";
import { loader } from "graphql.macro";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import useRoute from "../hooks/useRoute";
import { msg } from "../messages";
import useCustomNavigate from "./hooks/useCustomNavigate";
import ConfirmationModal from "./modals/ConfirmationModal";

const DELETE_OBJECTS = loader("../graphql/DeleteObjectsMutation.graphql");
const DASHBOARD_QUERY = loader("../graphql/DashboardQuery.graphql");
const UPDATE_PROPERTIES_BY_IDS = loader(
  "../graphql/UpdateObjectPropertiesByIdMutation.graphql"
);

const DeleteGroupModal = (props) => {
  const { groupIds } = props;
  const { entityId, isReport, isBoard } = useRoute();
  const history = useCustomNavigate();

  const [deleteObjects] = useMutation(DELETE_OBJECTS);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const { data: dashboardData } = useQuery(DASHBOARD_QUERY, {
    variables: {
      dashboardId: entityId(),
    },
  });

  const handleSave = () => {
    const targetLayout = dashboardData?.dashboard.layouts[0];
    const targetGroups = dashboardData?.dashboard.groups.filter(({ group }) =>
      groupIds.includes(group.id)
    );
    const widgetsToDelete = targetGroups
      .map(({ group }) =>
        group.widgets.map(({ widget }) => ({ id: widget.id }))
      )
      .flat(1);
    const newLayout = targetLayout.value.lg.filter(
      (item) => !groupIds.includes(item.i)
    );

    const promises = updateProperties({
      variables: {
        input: {
          propertiesArray: [
            {
              propertyId: targetLayout.id,
              value: {
                lg: newLayout,
                md: newLayout,
                sm: newLayout,
                xs: newLayout,
                xxs: newLayout,
              },
            },
          ],
        },
      },
    })
      .then(() =>
        deleteObjects({
          variables: {
            objects: [...widgetsToDelete, ...groupIds.map((id) => ({ id }))],
          },
        })
      )
      .then(() => {
        if (isReport()) {
          history(`/reports/${entityId()}`);
        }

        if (isBoard()) {
          history(`/boards/${entityId()}`);
        }

        submit();
      });

    toast.promise(
      promises,
      {
        loading: "Deleting group ...",
        success: () => `Group deleted`,
        error: (err) => `${err.toString()}`,
      }
    );
  };

  return (
    <ConfirmationModal
      modalOpen={props.isOpen}
      title={msg.deleteGroupModal.deleteGroup}
      handleClose={reject}
      buttons={
        <>
          <Button onClick={reject}>{msg.deleteGroupModal.buttonCancel}</Button>
          <Button color="primary" data-test-group="delete" onClick={handleSave}>
            {msg.deleteGroupModal.buttonDelete}
          </Button>
        </>
      }
    >
      {msg.formatString(msg.deleteGroupModal.text, {
        containerName: props.name,
      })}
    </ConfirmationModal>
  );
};

export default create(DeleteGroupModal);
