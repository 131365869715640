import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { msg } from "../../messages";
import CustomAutocomplete from "../CustomAutocomplete";

export const SCHEMAS_QUERY = gql`
  query listSchemas($filter: SchemaFilter, $first: Int) {
    schemata(orderBy: NAME_ASC, filter: $filter, first: $first) {
      name
      id
    }
  }
`;

const schemasAutocomplete = (props) => {
  const [schemas, setSchemas] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const [filter, setFilter] = useState({
    parentSchemaExists: false,
    objectsCount: {
      greaterThan: 0,
    },
    type: {
      equalTo: props.type.toUpperCase(),
    },
    enabled: {
      equalTo: true,
    },
    name: {
      includesInsensitive: debouncedValue,
    },
  });

  const [schemasLoad, schemasResult] = useLazyQuery(SCHEMAS_QUERY, {
    onCompleted: (e) => {
      setSchemas(
        e.schemata.map((item) => ({ value: item.id, title: item.name }))
      );

      if (props.schemaId) {
        setValue(props.schemaId);
      }
    },
    fetchPolicy: "network-only",
    variables: {
      first: 20,
      filter,
    },
  });

  const handleChangeType = (e) => {
    if (props.type === "monitor") {
      setFilter({
        parentSchemaExists: false,
        type: {
          equalTo: "DATASET",
        },
        mTags: {
          contains: ["application", "monitor", "object"],
        },
        objectsCount: {
          greaterThan: 0,
        },
        name: {
          includesInsensitive: debouncedValue,
        },
      });
    } else if (props.type === "stat") {
      setFilter({
        parentSchemaExists: false,
        type: {
          equalTo: "DATASET",
        },
        mTags: {
          contains: ["application", "statistics"],
        },
        objectsCount: {
          greaterThan: 0,
        },
        name: {
          includesInsensitive: debouncedValue,
        },
      });
    } else {
      setFilter({
        parentSchemaExists: false,
        objectsCount: {
          greaterThan: 0,
        },
        type: {
          equalTo: props.type.toUpperCase(),
        },
        enabled: {
          equalTo: true,
        },
        name: {
          includesInsensitive: debouncedValue,
        },
      });
    }
  };

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(inputValue);
    },
    500,
    [inputValue]
  );

  useEffect(() => {
    handleChangeType();
    schemasLoad();
  }, [debouncedValue, props.type]);

  useEffect(() => {
    setValue("");
    setSchemas([]);
  }, [props.type]);

  useEffect(() => {
    if (props.schemaId && schemas.length > 0) {
      const targetValue = schemas.find((item) => item.value === props.schemaId);

      if (targetValue) {
        handleChange({
          target: {
            name: props.name,
            rawValue: targetValue,
            value: targetValue?.value,
          },
        });
      } else {
        handleChange({
          target: {
            name: null,
            rawValue: null,
            value: null,
          },
        });
      }
    }
  }, [schemas]);

  const handleInputChange = (e) => {
    if (e && e.currentTarget.value !== 0) {
      setInputValue(e.currentTarget.value.trim());
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value);
    props.handleChange(e);
  };

  return (
    <>
      <CustomAutocomplete
        clearFieldIcon={true}
        error={props.error}
        helperText={props.helperText}
        autoComplete
        filterOptions={(x) => x}
        name={props.name}
        label={msg.addNewObjectModal.schemaName}
        list={schemas}
        value={value}
        loading={schemasResult.loading}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
    </>
  );
};

export default schemasAutocomplete;
