import { useApolloClient, useMutation } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { loader } from "graphql.macro";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "../components/CommonModal";
import { msg } from "../messages";
import { OBJECTS_QUERY } from "../queries";
import { updateProfile } from "../store/profileSlice";
import { useAuth } from "../utils/useAuth";
import CustomAutocomplete from "./CustomAutocomplete";
import CustomSelect from "./CustomSelect";

const UPDATE_OBJECT_PROPERTY = loader(
  "../graphql/UpdateObjectWithProperties.graphql"
);

const EditProgramSettingsModal = (props) => {
  const { loadUser } = useAuth();
  const profile = useSelector((state) => state.profile);

  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);

  let defaultValues = {};

  const client = useApolloClient();
  const [listOfObjects, setListOfObjects] = useState([]);
  const [listOfReports, setListOfReports] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const theme = useTheme();
  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  useEffect(() => {
    const queryObjects = async () => {
      setLoading(true);
      try {
        const resultBoards = await client.query({
          query: OBJECTS_QUERY,
          variables: {
            filter: {
              schemaTags: {
                contains: ["dashboard", "board"],
              },
            },
          },
          fetchPolicy: "network-only",
        });

        const resultReports = await client.query({
          query: OBJECTS_QUERY,
          variables: {
            filter: {
              schemaTags: {
                contains: ["report", "board"],
              },
            },
          },
          fetchPolicy: "network-only",
        });

        setListOfObjects(
          resultBoards.data.objects.map((item) => {
            return { value: item.id, title: item.name };
          })
        );

        setListOfReports(
          resultReports.data.objects.map((item) => {
            return { value: item.id, title: item.name };
          })
        );

        setValues(defaultValues);
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        setLoading(false);
      }
    };

    queryObjects();
  }, []);

  useEffect(() => {
    const queryProps = async () => {
      try {
        defaultValues["programDefaultDashboard"] =
          profile.programDefaultDashboard;
        defaultValues["programDefaultReport"] = profile.programDefaultReport;
        // defaultValues["showNotifications"] = profile.showNotifications;
        // defaultValues["notificationsSmsNotifications"] = profile.notificationsSmsNotifications;
        // defaultValues["notificationsEmailNotifications"] = profile.notificationsEmailNotifications;
        // defaultValues["notificationsSoundInTheApp"] = profile.notificationsSoundInTheApp;

        setValues(defaultValues);
      } catch (err) {
        toast.error(err.toString());
      } finally {
      }
    }; //queryProps

    queryProps();
  }, []);

  const dispatch = useDispatch();

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const handleUpdateWidgetProps = (valuesReady) => {
    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [
                {
                  objectId: profile.id,
                  keyedProperties: valuesReady,
                },
              ],
            },
          },
        }),
        {
          loading: "Updating...",
          success: () => "Updated",
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        submit();
        loadUser();
      });
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title="Program settings"
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>
              {msg.editNotificationsSettingsModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                dispatch(
                  updateProfile({
                    programDefaultDashboard: values.programDefaultDashboard,
                    programDefaultReport: values.programDefaultReport,
                  })
                );

                handleUpdateWidgetProps([
                  {
                    propertyKey: "programDefaultDashboard",
                    value: values.programDefaultDashboard,
                  },
                  {
                    propertyKey: "programDefaultReport",
                    value: values.programDefaultReport,
                  },
                ]);
              }}
            >
              {msg.editNotificationsSettingsModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              {msg.editNotificationsSettingsModal.general}
            </Typography>
          </Grid>

          <Grid item>
            <CustomSelect
              disabled={true}
              name="programTheme"
              label={"Theme"}
              list={[{ title: "Classic", value: "classic" }]}
              value={"classic"}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              disabled={true}
              label={"Language"}
              list={[{ title: "English", value: "english" }]}
              value={"english"}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={isLoading}
              name="programDefaultDashboard"
              label={"Default board"}
              list={listOfObjects}
              value={values["programDefaultDashboard"] ?? ""}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={isLoading}
              name="programDefaultReport"
              label={"Default report"}
              list={listOfReports}
              value={values["programDefaultReport"] ?? ""}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditProgramSettingsModal);
