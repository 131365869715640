export let mediaServer = window.__pixelConfig.APP_MEDIA_SERVER;
export const highlightSelectedStyle = "grayscale(0)"; // darkening selected widget

export const NOTIFICATION_TYPES = {
  NOTICE: "NOTICE",
  ALERT: "ALERT",
  ALERT_TRIGGERED: "ALERT_TRIGGERED",
  MESSAGE: "MESSAGE",
};

export const sizes = {
  tiny: 1,
  small: 2,
  medium: 8,
  big: 16,
};

export const TYPES = {
  COUNTER: "counter",
  CALCULATOR: "calculator",
  TIMESERIES: "timeseries",
  STATISTICS: "statistics",
  GROUP: "group",
  WIDGET: "widget",
  OBJECT: "object",
  MONITORING_ITEM: "monitor_item",
  DASHBOARD: "dashboard",
  COLLECTION: "collection",
};

export const COLOR_SCHEMAS = {
  DARK_ON_LIGHT: "darkonlight",
  LIGHT_ON_DARK: "lightondark",
  DARK_ON_TRANSPARENT: "darkontransparent",
  LIGHT_ON_TRANSPARENT: "lightontransparent",
};

export const DOC_URL =
  "https://pixel-networks.atlassian.net/wiki/spaces/PCV3/pages/11173966/PixelBoard";

export const OPERATORS = [
  { value: ">", title: ">" },
  { value: "<", title: "<" },
  { value: "=", title: "=" },
  { value: "!=", title: "!=" },
  { value: ">=", title: ">=" },
  { value: "<=", title: "<=" },
  {
    value: "contains",
    title: "Contains",
  },
];

// Time in seconds
export const DASHBOARD_STREAM_DATA_TIME = "300";

export const OBJECT_TYPES = [
  { value: "device", title: "Device" },
  { value: "dataset", title: "Dataset" },
  { value: "monitor", title: "Monitor objects" },
  { value: "stat", title: "Statistic objects" },
  { value: "application", title: "Application" },
];
