import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import { loader } from "graphql.macro";
import { SignJWT } from "jose";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import imgUpload from "../assets/upload.svg";
import CommonModal from "../components/CommonModal";
import CustomInput from "../components/CustomInput";
import FormField from "../components/FormField";
import { mediaServer } from "../constants";
import { msg } from "../messages";
import { DASHBOARD_QUERY } from "../queries";
import useFileUpload from "../utils/useFileUpload";
import CustomAutocomplete from "./CustomAutocomplete";
import DeleteDashboardModal from "./DeleteDashboardModal";
import useHandleCopy from "./side-card/menu/handlers/useHandleCopy";
import RpcSubscribeWrapper from "./side-card/static-table/RpcSubscribeWrapper";

const UPDATE_OBJECT_PROPERTY = loader(
  "../graphql/UpdateObjectWithProperties.graphql"
);
const DASHBOARD_QUERY_FULL = loader("../graphql/DashboardQuery.graphql");
const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      controlExecution {
        id
      }
      clientMutationId
    }
  }
`;

const LOAD_GROUP = gql`
  query loadGroups {
    userGroups {
      id
      isSystem
      groupName
      description
    }
  }
`;

const styles = (theme) => ({
  iconButton: {
    backgroundColor: "#000000",
    opacity: "0.44",
    height: "40px",
    width: "40px",
    color: "#ffffff",
    marginLeft: "16px",
    "&:hover": {
      backgroundColor: "#000000",
      opacity: "0.6",
    },
  },
});

const EditDashboardModal = (props) => {
  const [createExecution, { loading: controlLoading }] =
    useMutation(CREATE_EXECUTION);
  const groupsQuery = useQuery(LOAD_GROUP);

  const [dashboardLazyQuery] = useLazyQuery(DASHBOARD_QUERY_FULL, {
    variables: {
      dashboardId: props.dashboardId,
    },
    fetchPolicy: "network-only",
  });

  const [accessRights, setAccessRights] = useState({
    editorgroup: "",
    usergroup: "",
    readergroup: "",
  });

  const getGroups = () => {
    if (!groupsQuery.loading && groupsQuery.data) {
      return groupsQuery.data.userGroups.map((item) => ({
        value: item.id,
        title: item.groupName,
        disabled: item.groupName === "Nobody",
      }));
    }
    return [];
  };

  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);

  const client = useApolloClient();
  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();

  const handleCopy = useHandleCopy();

  const [description, setDescription] = useState("");

  const [fields, setFields] = useState([]);

  const [backgroundUID, setBackgroundUID] = useState(null);

  const { FileUpload, uploadInputRef, fileIsUploading, fileName } =
    useFileUpload();

  const handleUploaded = (result_uid, fileName) => {
    setBackgroundUID(result_uid); // should be passed to useFileUpload->handleFileUpload
    setValues({
      generalBackgroundImageUid: result_uid,
      generalBackgroundImageName: fileName,
    });
  };

  const getLinkToView = async () => {
    const secret = new TextEncoder().encode(
      "cc7e0d44fd473002f1c42167459001140ec6389b7353f8088f4d9a95f2f596f2"
    );
    const alg = "HS256";

    const jwt = await new SignJWT({
      dashboardId: props.dashboardId,
      type: "board",
      token: localStorage.getItem("refreshToken"),
    })
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .setExpirationTime("1y")
      .sign(secret);

    handleCopy({
      message: "Link to view copied",
      text: `${window.location.origin}/view/?token=${jwt}/?mode=0`,
    });
  };

  const handleUpdateDashboard = () => {
    const valuesReady = Object.keys(values).map((key) => {
      return { propertyKey: key, value: values[key] };
    });

    const payload = {
      objectId: props.dashboardId,
      name: formik.values.name,
      description,
      keyedProperties: valuesReady,
    };

    if (accessRights.editorgroup) {
      payload.editorgroup = accessRights.editorgroup;
    }

    if (accessRights.readergroup) {
      payload.readergroup = accessRights.readergroup;
    }

    if (accessRights.usergroup) {
      payload.usergroup = accessRights.usergroup;
    }

    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [payload],
            },
          },
        }).then(() => dashboardLazyQuery()),
        {
          loading: "Updating...",
          success: () => "Updated",
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        submit();
      });
  };

  const validationSchema = yup.object({
    name: yup.string().trim().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema,
    onSubmit: () => {
      handleUpdateDashboard();
    },
  });

  const handleError = (message) => {
    toast.error(message);
  };

  let defaultValues = {};
  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result = await client.query({
          query: DASHBOARD_QUERY,
          variables: { objId: props.dashboardId },
          fetchPolicy: "network-only",
        });
        // default values to fill input fields
        result.data.object.objectProperties.forEach((prop) => {
          defaultValues[prop.key] = prop.value;
        });

        setAccessRights({
          readergroup: result.data.object.readergroup,
          usergroup: result.data.object.usergroup,
          editorgroup: result.data.object.editorgroup,
        });
        // fields description and types for components {valueSet, key, description, type.name}
        const fields = result.data.object.objectProperties.map((prop) => {
          return {
            key: prop.key,
            description: prop.spec.description,
            type: prop.spec.type,
            valueSet: prop.spec.valueSet,
            groupName: prop.groupName,

            valueRange: prop.spec.valueRange,
            hidden: prop.spec.hidden,
          };
        });

        setBackgroundUID(defaultValues["generalBackgroundImageUid"]);

        setFields(fields); // description/component types for each field
        setValues(defaultValues); // loaded values for each field
        formik.setFieldValue("name", result.data.object.name);
        setDescription(result.data.object.description);
      } catch (err) {
        handleError(err.toString());
      } finally {
      }
    }; //queryProps

    queryProps();
  }, []);

  const dispatch = useDispatch();

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const handleDelete = async (e) => {
    setBackgroundUID(null);
    setValues({
      generalBackgroundImageUid: null,
      generalBackgroundImageName: null,
    });
  }; //handleFileDelete

  const backgroundUrl = `${mediaServer}/download/${backgroundUID}/${localStorage.getItem(
    "authToken"
  )}?${fileName}`;
  const backgroundBlankUrl = imgUpload; // no background defined, show svg

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "CopyDashboard",
            objectId: props.dashboardId,
            params: {
              UUID: props.dashboardId,
              NAME: `${formik.values.name} copy`,
            },
          },
        },
      },
    });
  };
  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editDashboardModal.editDashboard}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose} data-test-dashboard="cancel">
              {msg.editDashboardModal.buttonCancel}
            </Button>
            <Button
              data-test-dashboard="update"
              color="primary"
              onClick={formik.handleSubmit}
            >
              {msg.editDashboardModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              name="name"
              label={msg.editDashboardModal.name}
              clearFieldIcon={true}
              value={formik.values.name}
              onChange={formik.handleChange}
              helperText={formik.errors.name}
              error={formik.errors.name}
            />
          </Grid>
          {/*<Grid item>*/}
          {/*  <CustomAutocomplete*/}
          {/*    disabled={collectionsQuery.loading}*/}
          {/*    label={"Collection"}*/}
          {/*    list={getCollections()}*/}
          {/*    value={collection}*/}
          {/*    onChange={(e) => {*/}
          {/*      console.log(e);*/}
          {/*      setCollection(e.target.value)*/}
          {/*    }}*/}
          {/*    clearFieldIcon={true}*/}
          {/*  />*/}
          {/*</Grid>*/}
          {fields
            .filter((item) => item.groupName === "General" && !item.hidden)
            .map((field) => (
              <FormField
                key={field.key}
                values={values}
                field={field}
                handleInputChange={handleInputChange}
              />
            ))}

          <Grid item>
            <InputLabel>{msg.editDashboardModal.backgroundImage}</InputLabel>
          </Grid>

          <Grid item>
            <div
              style={{
                width: "100%",
                minHeight: "120px",
                maxHeight: "360px",
                margin: "0",
                marginLeft: "0",
                padding: 0,
                backgroundImage: `url(${
                  backgroundUID ? backgroundUrl : backgroundBlankUrl
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div style={{ position: "relative" }}>
                <img
                  src={`${backgroundUID ? backgroundUrl : backgroundBlankUrl}`}
                  style={{ width: "100%", opacity: "0" }}
                />
              </div>

              {/* buttons upload/copy/delete */}
              <div style={{ position: "absolute", right: "16px", top: "8px" }}>
                {backgroundUID && (
                  <IconButton
                    className={classes.iconButton}
                    onClick={(e) => {
                      handleCopy({
                        message: msg.editDashboardModal.copied,
                        text: backgroundUID,
                      });
                    }}
                    size="large"
                  >
                    <FilterNoneIcon />
                  </IconButton>
                )}

                {!backgroundUID && (
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      uploadInputRef.current && uploadInputRef.current.click();
                    }}
                    size="large"
                  >
                    <CloudUploadIcon />
                  </IconButton>
                )}

                {backgroundUID && (
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleDelete}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>

              <div style={{ position: "absolute", width: "100%" }}>
                {fileIsUploading && (
                  <LinearProgress style={{ width: "100%" }} />
                )}
              </div>

              {!fileIsUploading && (
                <FileUpload
                  file_uid={backgroundUID}
                  accept={["image/png", "image/jpeg"]}
                  maxSize={10000000}
                  handleUploaded={handleUploaded}
                  handleError={handleError}
                />
              )}
            </div>
          </Grid>

          <Grid item>
            <CustomInput
              name="description"
              label={msg.editDashboardModal.description}
              clearFieldIcon={true}
              value={description}
              multiline={true}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>

          <Grid item>
            <CustomAutocomplete
              label={"Editors group"}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.editorgroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  editorgroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={groupsQuery.loading}
              label={"Users group"}
              list={getGroups()}
              value={accessRights.usergroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  usergroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={"Readers group"}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.readergroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  readergroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item container>
            <Button
              data-test="copyDashboardId"
              variant="outlined"
              color="primary"
              fullWidth
              disableElevation
              style={{ marginTop: "20px" }}
              onClick={(e) => {
                handleCopy({
                  message: msg.editDashboardModal.copied,
                  text: props.dashboardId,
                });
              }}
            >
              {msg.editDashboardModal.buttonCopy}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              disableElevation
              style={{ marginTop: "20px" }}
              onClick={getLinkToView}
            >
              Get link to view
            </Button>

            <RpcSubscribeWrapper
              rpcName={"CopyBoard"}
              objectId={props.dashboardId}
              object={null}
              handler={rpcHandler}
              title={"Copy Board"}
              successCb={(dashboardId) => {
                if (dashboardId) {
                  window.location.href = `/boards/${dashboardId}`;
                }
              }}
            >
              <LoadingButton
                data-test-copy-board-rpc
                variant="outlined"
                color="primary"
                fullWidth
                disableElevation
                style={{ marginTop: "20px" }}
              ></LoadingButton>
            </RpcSubscribeWrapper>

            <Button
              data-test="deleteDashboard"
              variant="contained"
              color="secondary"
              fullWidth
              disableElevation
              style={{ marginTop: "16px" }}
              onClick={(e) => {
                DeleteDashboardModal({
                  open: true,
                  dashboardId: props.dashboardId,
                  name: formik.values.name,
                })
                  .then(() => {
                    submit();
                  })
                  .catch(() => {});
              }}
            >
              {msg.editDashboardModal.buttonDelete}
            </Button>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditDashboardModal);
