import { useApolloClient, useMutation } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { loader } from "graphql.macro";
import { useEffect, useReducer } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { useDispatch } from "react-redux";
import CommonModal from "../components/CommonModal";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import CustomSelectColor from "../components/CustomSelectColor";
import { OPERATORS } from "../constants";
import { msg } from "../messages";
import { WIDGET_QUERY } from "../queries";
import CustomSelectIcon from "./CustomSelectIcon";

const UPdATE_OBJECT_PROPERTY = loader(
  "../graphql/UpdateObjectWithProperties.graphql"
);

const styles = (theme) => ({
  stateHeadline: {
    marginTop: "15px",
  },
});

const EditWidgetColorsModal = (props) => {
  const [updateProperty] = useMutation(UPdATE_OBJECT_PROPERTY);

  const theme = useTheme();
  const client = useApolloClient();

  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const defaultColors = [
    {
      title: "Default",
      value: theme.palette.wBlue,
    },
    {
      title: "Gray",
      value: theme.palette.chartColors.gray,
    },
    {
      title: "Green",
      value: theme.palette.chartColors.green,
    },
    {
      title: "Orange",
      value: theme.palette.chartColors.orange,
    },
    {
      title: "Red",
      value: theme.palette.chartColors.red,
    },
  ];

  let defaultValues = {};

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result = await client.query({
          query: WIDGET_QUERY,
          variables: { objId: props.widgetId },
          fetchPolicy: "network-only",
        });

        const getPropValue = (prop) =>
          result.data.object.objectProperties.find((obj) => obj.key === prop)
            ?.value;

        defaultValues["colorsDefaultColor"] =
          getPropValue("colorsDefaultColor");
        defaultValues["settingsIcon"] = getPropValue("settingsIcon");

        defaultValues["colorsColor1Condition_color"] = getPropValue(
          "colorsColor1Condition"
        ).color;
        defaultValues["colorsColor1Condition_operator"] = getPropValue(
          "colorsColor1Condition"
        ).operator;
        defaultValues["colorsColor1Condition_value"] = getPropValue(
          "colorsColor1Condition"
        ).value;
        defaultValues["colorsColor1Condition_icon"] = getPropValue(
          "colorsColor1Condition"
        ).icon;

        defaultValues["colorsColor2Condition_color"] = getPropValue(
          "colorsColor2Condition"
        ).color;
        defaultValues["colorsColor2Condition_operator"] = getPropValue(
          "colorsColor2Condition"
        ).operator;
        defaultValues["colorsColor2Condition_value"] = getPropValue(
          "colorsColor2Condition"
        ).value;
        defaultValues["colorsColor2Condition_icon"] = getPropValue(
          "colorsColor2Condition"
        ).icon;

        defaultValues["colorsColor3Condition_color"] = getPropValue(
          "colorsColor3Condition"
        ).color;
        defaultValues["colorsColor3Condition_operator"] = getPropValue(
          "colorsColor3Condition"
        ).operator;
        defaultValues["colorsColor3Condition_value"] = getPropValue(
          "colorsColor3Condition"
        ).value;
        defaultValues["colorsColor3Condition_icon"] = getPropValue(
          "colorsColor3Condition"
        ).icon;

        setValues(defaultValues); // loaded values for each field
      } catch (err) {
        toast.error(err.toString());
      }
    };

    queryProps();
  }, []);

  const dispatch = useDispatch();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  if (Object.keys(values).length === 0) return false;

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editWidgetColorsModal.colorsSettings}
        handleClose={reject}
        buttons={
          <>
            <Button
              onClick={handleClose}
              data-test-widget-colors="WidgetCancel"
            >
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button
              data-test-widget-colors="WidgetSave"
              color="primary"
              onClick={() => {
                const valuesReady = [
                  {
                    propertyKey: "colorsDefaultColor",
                    value: values.colorsDefaultColor,
                  },
                  {
                    propertyKey: "settingsIcon",
                    value: values.settingsIcon,
                  },
                  {
                    propertyKey: "colorsColor1Condition",
                    value: {
                      operator: values.colorsColor1Condition_operator,
                      value: values.colorsColor1Condition_value,
                      color: values.colorsColor1Condition_color,
                      icon: values.colorsColor1Condition_icon,
                    },
                  },
                  {
                    propertyKey: "colorsColor2Condition",
                    value: {
                      operator: values.colorsColor2Condition_operator,
                      value: values.colorsColor2Condition_value,
                      color: values.colorsColor2Condition_color,
                      icon: values.colorsColor2Condition_icon,
                    },
                  },
                  {
                    propertyKey: "colorsColor3Condition",
                    value: {
                      operator: values.colorsColor3Condition_operator,
                      value: values.colorsColor3Condition_value,
                      color: values.colorsColor3Condition_color,
                      icon: values.colorsColor3Condition_icon,
                    },
                  },
                ];

                toast
                  .promise(
                    updateProperty({
                      variables: {
                        input: {
                          detailedObject: [
                            {
                              objectId: props.widgetId,
                              keyedProperties: valuesReady,
                            },
                          ],
                        },
                      },
                    }),
                    {
                      loading: "Updating colors ...",
                      success: () => `Colors updated`,
                      error: (err) => `${err.toString()}`,
                    }
                  )
                  .then(() => submit());
              }}
            >
              {msg.editWidgetModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              {msg.editWidgetColorsModal.defaultColor}
            </Typography>
          </Grid>

          <Grid item>
            <CustomSelectColor
              name="colorsDefaultColor"
              colors={["default", "red", "blue", "green", "yellow", "orange"]}
              label={msg.editWidgetColorsModal.color}
              value={values["colorsDefaultColor"] ?? ""}
              onChange={handleInputChange}
              data-test-color-input="colorSelect"
            />
          </Grid>

          <Grid item>
            <CustomSelectIcon
              filter={["application", "monitor", "icon"]}
              name="settingsIcon"
              label={"Icon"}
              value={values["settingsIcon"] ?? ""}
              onChange={handleInputChange}
              data-test-color-icon="colorIcon"
            />
          </Grid>

          {/* state 1 */}

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.stateHeadline}
            >{`${msg.editWidgetColorsModal.state} 1`}</Typography>
          </Grid>

          <Grid item>
            <CustomSelectColor
              clearFieldIcon={true}
              name="colorsColor1Condition_color"
              colors={["default", "red", "blue", "green", "yellow", "orange"]}
              label={msg.editWidgetColorsModal.objectColor}
              value={values["colorsColor1Condition_color"] ?? ""}
              onChange={handleInputChange}
              data-test-color-object="objectColor"
            />
          </Grid>
          <Grid item>
            <CustomSelectIcon
              clearFieldIcon={true}
              filter={["application", "monitor", "icon"]}
              name="colorsColor1Condition_icon"
              label={"Icon"}
              value={values["colorsColor1Condition_icon"] ?? ""}
              onChange={handleInputChange}
              data-test-color-icon="iconColor"
            />
          </Grid>

          <Grid item container>
            <Grid item xs={4} style={{ paddingRight: "10px" }}>
              <CustomSelect
                clearFieldIcon={true}
                name="colorsColor1Condition_operator"
                label={msg.editWidgetColorsModal.option}
                list={OPERATORS}
                value={values["colorsColor1Condition_operator"] ?? ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={8} alignContent="flex-end">
              <CustomInput
                name="colorsColor1Condition_value"
                label="&nbsp;"
                clearFieldIcon={true}
                value={values["colorsColor1Condition_value"] ?? ""}
                onChange={handleInputChange}
                type="string"
              />
            </Grid>
          </Grid>

          {/* state 2 */}

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.stateHeadline}
            >{`${msg.editWidgetColorsModal.state} 2`}</Typography>
          </Grid>

          <Grid item>
            <CustomSelectColor
              clearFieldIcon={true}
              name="colorsColor2Condition_color"
              colors={["default", "red", "blue", "green", "yellow", "orange"]}
              label={msg.editWidgetColorsModal.objectColor}
              value={values["colorsColor2Condition_color"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <CustomSelectIcon
              clearFieldIcon={true}
              filter={["application", "monitor", "icon"]}
              name="colorsColor2Condition_icon"
              label={"Icon"}
              value={values["colorsColor2Condition_icon"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item container>
            <Grid item xs={4} style={{ paddingRight: "10px" }}>
              <CustomSelect
                clearFieldIcon={true}
                name="colorsColor2Condition_operator"
                label={msg.editWidgetColorsModal.option}
                list={OPERATORS}
                value={values["colorsColor2Condition_operator"] ?? ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={8} alignContent="flex-end">
              <CustomInput
                clearFieldIcon={true}
                name="colorsColor2Condition_value"
                label="&nbsp;"
                value={values["colorsColor2Condition_value"] ?? ""}
                onChange={handleInputChange}
                type="string"
              />
            </Grid>
          </Grid>

          {/* state 3 */}

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.stateHeadline}
            >{`${msg.editWidgetColorsModal.state} 3`}</Typography>
          </Grid>

          <Grid item>
            <CustomSelectColor
              clearFieldIcon={true}
              name="colorsColor3Condition_color"
              colors={["default", "red", "blue", "green", "yellow", "orange"]}
              label={msg.editWidgetColorsModal.objectColor}
              value={values["colorsColor3Condition_color"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <CustomSelectIcon
              clearFieldIcon={true}
              filter={["application", "monitor", "icon"]}
              name="colorsColor3Condition_icon"
              label={"Icon"}
              value={values["colorsColor3Condition_icon"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item container>
            <Grid item xs={4} style={{ paddingRight: "10px" }}>
              <CustomSelect
                clearFieldIcon={true}
                name="colorsColor3Condition_operator"
                label={msg.editWidgetColorsModal.option}
                list={OPERATORS}
                value={values["colorsColor3Condition_operator"] ?? ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={8} alignContent="flex-end">
              <CustomInput
                name="colorsColor3Condition_value"
                label="&nbsp;"
                clearFieldIcon={false}
                value={values["colorsColor3Condition_value"] ?? ""}
                onChange={handleInputChange}
                type="string"
              />
            </Grid>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgetColorsModal);
