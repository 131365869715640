import { gql, useQuery } from "@apollo/client";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Fab from "@mui/material/Fab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { loader } from "graphql.macro";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useMatch, useSearchParams } from "react-router-dom";
import ContentLayout from "../../../components/ContentLayout";
import useCustomNavigate from "../../../components/hooks/useCustomNavigate";
import SplitView from "../../../components/icons/splitView";
import NoDashboards from "../../../components/NoDashboards";
import Spinner from "../../../components/Spinner";
import { setSettings } from "../../../store/settingsSlice";

const REPORTS_QUERY = loader("../../../graphql/ReportsQuery.graphql");
const DASHBOARDS_QUERY = loader("../../../graphql/DashboardsQuery.graphql");

const GET_OBJECT = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      id
    }
  }
`;

const BoardsRoot = () => {
  const { loading, error, data } = useQuery(DASHBOARDS_QUERY);
  const {
    loading: loadingReports,
    error: errorReports,
    data: dataReports,
  } = useQuery(REPORTS_QUERY);
  const navigate = useCustomNavigate();
  const matchBoardsRoot = useMatch("/boards");
  const dispatch = useDispatch();
  const isFullScreen = useSelector((state) => state.settings.isFullScreen);
  const isViewMode = useSelector((state) => state.settings.isViewMode);
  const profile = useSelector((state) => state.profile);
  const [search, setSearch] = useSearchParams();

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(setSettings({
      isFullScreen: isSm
    }))
  }, []);

  useEffect(() => {
    if (search.get("mode")) {
      dispatch(
        setSettings({
          isViewMode: true,
          isFullScreen: true,
          isDrawerOpen: false,
        })
      );
    }

    const checkBeforeLoad = async () => {
      const handleRedirect = () => {
        const ids = data.dashboards.map((item) => item.id);
        const idsReports = dataReports.dashboards.map((item) => item.id);
        if (ids.length > 0 && matchBoardsRoot) {
          return navigate(`/boards/${ids[0]}`);
        }

        if (ids.length === 0 && idsReports.length > 0) {
          return navigate(`/reports/`);
        }
      };

      const handleDefaultEntity = () => {
        let isBothDeleted = false;

        const ids = data.dashboards.map((item) => item.id);
        const idsReports = dataReports.dashboards.map((item) => item.id);

        if (!ids.includes(profile.programDefaultDashboard)
          || !idsReports.includes(profile.programDefaultReport)) {
          handleRedirect();
        }

        if (profile?.programDefaultDashboard && matchBoardsRoot) {
          if (ids.includes(profile.programDefaultDashboard)) {
            return navigate(`/boards/${profile.programDefaultDashboard}`);
          }
        }

        if (profile?.programDefaultReport && matchBoardsRoot) {
          if (idsReports.includes(profile.programDefaultReport)) {
            return navigate(`/reports/${profile.programDefaultReport}`);
          }
        }
      };
      if (profile && data) {
        if (profile?.programDefaultDashboard || profile?.programDefaultReport) {
          handleDefaultEntity();
        } else {
          handleRedirect();
        }
      }
    };

    checkBeforeLoad();
  }, [matchBoardsRoot, data, loading, profile, dataReports]);

  if (!profile) {
    return null;
  }

  return (
    <>
      {loading && !data && <Spinner />}
      {error && <>{error}</>}
      {!loading && data && !data.dashboards.length && <NoDashboards />}
      {!error && data && (
        <>
          {!isFullScreen && isSm && (
            <Fab
              style={{
                right: "20px",
                position: "fixed",
                bottom: "20px",
                zIndex: 1201,
              }}
              color="primary"
              onClick={() => {
                dispatch(setSettings({ isFullScreen: true }));
              }}
            >
              <DashboardIcon />
            </Fab>
          )}
          {isFullScreen && isSm && !isViewMode && (
            <Fab
              style={{
                right: "20px",
                position: "fixed",
                bottom: "20px",
                zIndex: 1201,
              }}
              color="primary"
              onClick={() => {
                dispatch(setSettings({ isFullScreen: false }));
              }}
            >
              <SplitView />
            </Fab>
          )}
          <ContentLayout>
            <Outlet />
          </ContentLayout>
        </>
      )}
    </>
  );
};

export default BoardsRoot;
