import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { loader } from "graphql.macro";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import CommonModal from "../components/CommonModal";
import CustomAutocomplete from "../components/CustomAutocomplete";
import { msg } from "../messages";
import { REPORTS_QUERY_WITHOUT_COLLECTIONS } from "../modules/reports/api/ReportsList";

const LINK_OBJECTS = loader("../graphql/LinkObjects.graphql");

export const OBJECTS_QUERY = gql`
  query listDevices {
    dashboards: objects(
      filter: { schemaTags: { contains: ["board", "dashboard"] } }
      orderBy: NAME_ASC
    ) {
      id
      name
    }
    reports: objects(
      filter: { schemaTags: { contains: ["board", "report"] } }
      orderBy: NAME_ASC
    ) {
      id
      name
    }
  }
`;

const DASHBOARDS_QUERY_WITHOUT_COLLECTIONS = gql(/* GraphQL */ `
    query getDashboardsWithoutCollections {
        dashboards: objects(
            filter: {
                schemaTags: { contains: ["application", "board", "dashboard"] }
                objectsToObjectsByObject1IdConnection: {
                    every: { object2: { not: { schemaTags: { contains: ["collection"] } } } }
                }
            }
            orderBy: NAME_ASC
        ) {
            id
            name
        }
    }
`);


const LinkDashboardModal = (props) => {
  const [listOfObjects, setListOfObjects] = useState([]);
  const [linkObjects] = useMutation(LINK_OBJECTS);

  const [listDevices] = useLazyQuery(OBJECTS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {},
  });

  const [dashboardsLazyQuery] = useLazyQuery(REPORTS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: "network-only",
  });

  const [dashboardsWithoutCollectionsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: "network-only",
  });

  let defaultValues = {};

  const [isLoading, setLoading] = useState(false);

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  useEffect(() => {
    const queryObjects = async () => {
      setLoading(true);
      try {
        const result = await listDevices();

        const dashboards = result.data.dashboards.map((item) => ({
          value: item.id,
          title: item.name,
          board: "Dashboards",
        }));
        const reports = result.data.reports.map((item) => ({
          value: item.id,
          title: item.name,
          board: "Reports",
        }));
        setListOfObjects([...dashboards, ...reports]);

        setValues(defaultValues);
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        setLoading(false);
      }
    };

    queryObjects();
  }, []);

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const handleLinkWidget = () => {
    const variables = {
      widgetId: values["object"],
      objectId: props.widgetId,
    };

    toast
      .promise(
        linkObjects({
          variables,
        }),
        {
          loading: "Linking dashboard...",
          success: () => `Dashboard linked`,
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        props.refetch(
          {
            objectId: props.widgetId,
          },
          {
            fetchPolicy: "network-only",
          }
        );
        return Promise.allSettled([
          dashboardsLazyQuery(),
          dashboardsWithoutCollectionsLazyQuery()
        ])
      })
      .then(() => {
       submit();
    });
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title="Add existing dashboard/report"
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleLinkWidget}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomAutocomplete
              groupBy={({ board }) => board}
              disabled={isLoading}
              name="object"
              label={"Dashboard to link"}
              list={listOfObjects}
              value={values["object"] ?? ""}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(LinkDashboardModal);
