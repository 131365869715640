import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { loader } from "graphql.macro";
import PropTypes from "prop-types";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { useMatch } from "react-router-dom";
import * as yup from "yup";
import CommonModal from "../components/CommonModal";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import CustomSwitch from "../components/CustomSwitch";
import { msg } from "../messages";
import { SCHEMAS_NAMES_QUERY } from "../queries";
import CustomAutocomplete from "./CustomAutocomplete";
import useCustomNavigate from "./hooks/useCustomNavigate";

const ADD_OBJECT_MUTATION = loader("../graphql/AddObjectMutation.graphql");

const AddNewObjectModal = (props) => {
  const client = useApolloClient();
  const [listOfSchemaNames, setListOfSchemaNames] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const routeMatch = useMatch("/:lang/:dashboardId/:groupId/:widgetId");

  const history = useCustomNavigate();
  const [addObject, { loading }] = useMutation(ADD_OBJECT_MUTATION);

  let defaultValues = {};

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  //TODO: load list of schema names here (instead of current hardcoded list)
  useEffect(() => {
    defaultValues["schemaType"] = "";
    defaultValues["schemaName"] = "";
    defaultValues["enabled"] = true;
    setValues(defaultValues);
    setName("");
    setDescription("");
  }, []);

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const validationSchema = yup.object({
    name: yup.string().trim().required("Name is required"),
    // schemaName: yup.string().nullable().required("Schema name is required"),
    schemaType: yup.string().trim().required("Schema type is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      schemaName: "",
      schemaType: "",
    },
    validationSchema: validationSchema,
    onSubmit: (valuesForm, actions) => {
      toast
        .promise(
          addObject({
            variables: {
              name: valuesForm.name,
              description: description,
              schemaId: values["schemaName"],
              enabled: values["enabled"],
              widgetId: props.widgetId,
            },
          }),
          {
            loading: "Creating new object...",
            success: () => `Object created`,
            error: (err) => `${err.toString()}`,
          }
        )
        .then((result) => {
          const dashboardId = routeMatch.params.dashboardId;
          const groupId = routeMatch.params.groupId;
          const widgetId = routeMatch.params.widgetId;
          const objectId = result.data.createObjectWithProperties.uuid;
          const lang = routeMatch.params.lang;

          console.table({ dashboardId, groupId, widgetId, objectId, lang });

          actions.resetForm();
          props.refetch(
            {
              objectId: props.widgetId,
            },
            {
              fetchPolicy: "network-only",
            }
          );
          submit();
          // history(`/${lang}/${dashboardId}/${groupId}/${widgetId}/${objectId}/`);
        });
    },
  });

  const [getSchemaNames] = useLazyQuery(SCHEMAS_NAMES_QUERY, {
    variables: { schemaType: formik.values.schemaType },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setListOfSchemaNames(
        data.schemata.map((item) => {
          return { value: item.id, title: item.name };
        })
      );
    },
  });

  useEffect(() => {
    if (formik.values.schemaType) getSchemaNames();
  }, [formik.values.schemaType]);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.addNewObjectModal.addNewObject}
        forceTitle={true}
        contentStyles={{
          padding: "14px 16px 16px 14px",
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>
              {msg.addNewObjectModal.buttonCancel}
            </Button>
            <Button color="primary" type="submit" onClick={formik.handleSubmit}>
              {msg.addNewObjectModal.buttonAdd}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              name="name"
              label={msg.addNewObjectModal.name}
              clearFieldIcon={true}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="schemaType"
              label={msg.addNewObjectModal.schemaType}
              list={[
                { value: "DEVICE", title: "Device" },
                { value: "DATASET", title: "Dataset" },
                { value: "APPLICATION", title: "Application" },
              ]}
              value={formik.values.schemaType}
              onChange={formik.handleChange}
              error={
                formik.touched.schemaType && Boolean(formik.errors.schemaType)
              }
              helperText={formik.touched.schemaType && formik.errors.schemaType}
            />
          </Grid>

          <Grid item>
            <CustomAutocomplete
              name="schemaName"
              label={msg.addNewObjectModal.schemaName}
              list={listOfSchemaNames}
              value={values["schemaName"] ?? ""}
              onChange={handleInputChange}
              disabled={formik.values.schemaType === ""}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomSwitch
              name="enabled"
              label={msg.addNewObjectModal.enabled}
              value={values["enabled"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomInput
              name="description"
              label={msg.addNewObjectModal.description}
              clearFieldIcon={true}
              value={description ?? ""}
              multiline={true}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

AddNewObjectModal.propTypes = {
  widgetId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default create(AddNewObjectModal);
