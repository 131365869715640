import { TableContainer } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import { forwardRef, useState } from "react";
import { ReactComponent as MenuDown } from "../../assets/menu-down.svg";
import { COLOR_SCHEMAS, highlightSelectedStyle } from "../../constants";
import useColors from "../../utils/useColors";
import { BASE_HEIGHT } from "../../utils/widgetSizes";
import EmptyTableWithColumns from "../common/EmptyTableWithColumns";
import EmptyTableWithoutColumns from "../common/EmptyTableWithoutColumns";
import WidgetEditControls from "../WidgetEditControls";

import { TableVirtuoso } from "react-virtuoso";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "#2780E3",
    height: "100%",
  },
  tableCellHead: {
    userSelect: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "none",
    fontSize: "16px",
    fontFamily: "Roboto-Medium",
  },

  tableCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "none",
    fontSize: "16px",
    fontFamily: "Roboto-Regular",
  },
}));

const W_StaticTable = (props) => {
  const { objectProperties, selected, id } = props;

  const getPropValue = (prop) =>
    objectProperties.find((obj) => obj.key === prop)?.value;
  const valueValue = objectProperties.find(
    (obj) => obj.key === "valueValue"
  )?.value;
  const columns = objectProperties.find(
    (obj) => obj.key === "settingsColumns"
  )?.value;
  const colors = [getPropValue("settingsStyle"), null];

  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(
    getPropValue("settingsStyle")
  );
  const classes = useStyles();

  const getColorOfRow = (index) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return "";
    }

    const theme = colors[0];

    switch (theme) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return "#F1F1F1";
      default:
        return "rgba(255, 255, 255, 0.1)";
    }
  };

  const [orderByType, setOrderBy] = useState("desc");

  function fixedHeaderContent() {
    return (
      <TableRow
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
      >
        <TableCell
          onClick={() => {
            setOrderBy(orderByType === "desc" ? "asc" : "desc");
          }}
          className={classes.tableCellHead}
          style={{
            color: fgColor,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "38px",
              alignItems: "center",
            }}
          >
            <span>Date time</span>
            {orderByType === "desc" && (
              <MenuDown
                style={{
                  height: "20px",
                }}
              />
            )}
            {orderByType === "asc" && (
              <MenuDown
                style={{
                  transform: "rotate(180deg)",
                  height: "20px",
                }}
              />
            )}
          </div>
        </TableCell>
        {columns.columns.map((item) => (
          <TableCell
            align="right"
            className={classes.tableCellHead}
            style={{ color: fgColor }}
          >
            {item.value.title}
            {item.mode && (
              <>
                <br />({item.mode})
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const rowContent = (_index, item) => {
    return (
      <>
        <TableCell
          component="th"
          scope="row"
          className={classes.tableCell}
          style={{ color: fgColor }}
        >
          {item["time"]}
        </TableCell>
        {columns.columns.map((column, index) => (
          <>
            <TableCell
              scope="row"
              className={classes.tableCell}
              style={{
                color: fgColor,
                textAlign: "right",
                width: "1px",
                whiteSpace: "nowrap",
              }}
            >
              {`${
                item.data.find((item) => {
                  const id = `${column.value.value}_${column.mode}`;
                  const idComing = `${item.propertyId}_${item.mode}`;
                  return id === idComing;
                })?.value
              }`}
            </TableCell>
          </>
        ))}
      </>
    );
  }

  const VirtuosoTableComponents = {
    Scroller: forwardRef((props, ref) => (
      <TableContainer {...props} ref={ref} />
    )),
    Table: (props) => <Table {...props}
        size="small"
        className={classes.table}
        aria-label="simple table"
        style={{ backgroundColor: "transparent" }}
    />,
    TableHead: ({ ...props }) => (
      <TableHead
        {...props}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: bgColor,
          height: `${BASE_HEIGHT}px`,
        }}
      ></TableHead>
    ),
    TableRow: (props) => <TableRow  {...props}  style={{ height: `${BASE_HEIGHT}px`, backgroundColor: getColorOfRow(props['data-index']) }} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  return (
    <div
      className={"force-scroll"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100%",
        position: "relative",
        overflow: "auto",
        backgroundColor: bgColor,
        filter: selected ? highlightSelectedStyle : "",
        borderRadius: "2px",
      }}
    >
      {valueValue.length > 0 && columns.columns.length > 0 && (
        <div style={{ width: "100%", height: "100%" }}>
          <TableVirtuoso
            size="small"
            data={orderByType === "desc" ? valueValue : valueValue.map(item => item).reverse()}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
          />
        </div>
      )}

      {columns.columns.length === 0 && valueValue.length === 0 && (
        <EmptyTableWithoutColumns fgColor={fgColor} />
      )}

      {columns.columns.length > 0 && valueValue.length === 0 && (
        <EmptyTableWithColumns
          fgColor={fgColor}
          infoText={
            'If you just added new columns you need to click "Update table" button'
          }
        />
      )}
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_StaticTable;
