import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import { Button } from "@mui/material";
import { loader } from "graphql.macro";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { matchPath } from "react-router-dom";
import { msg } from "../messages";
import useCustomNavigate from "./hooks/useCustomNavigate";
import ConfirmationModal from "./modals/ConfirmationModal";
const DASHBOARDS_QUERY = loader("../graphql/DashboardsQuery.graphql");

const DELETE_OBJECTS = gql`
    mutation remove($input: mnDeleteObjectInput!) {
        mnDeleteObject(input: $input) {
            clientMutationId
        }
    }
`;

const GET_IDS_FOR_DELETE = gql`
    query GetIdsForDelete($objectId: UUID!) {
        dashboard: object(id: $objectId) {
            groups: objectsToObjectsByObject1Id(filter: { object2: { schemaTags: { contains: ["group"] } } }) {
                id
                group: object2 {
                    id
                    name
                    widgets: objectsToObjectsByObject1Id {
                        widget: object2 {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

const DeleteDashboardModal = (props) => {
  const client = useApolloClient();
  const history = useCustomNavigate();

  const [dashboardsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY, {
    fetchPolicy: "network-only",
  });

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleDeleteDashboard = async () => {
    const toastId = toast.loading("Deleting dashboard...");
    const matchBoard = matchPath(
      { path: "/boards/:dashboardId" },
      location.pathname
    );

    try {
      const result = await client.query({
        query: GET_IDS_FOR_DELETE,
        variables: {
          objectId: props.dashboardId,
        },
      });

      const ids = [props.dashboardId];

      result.data.dashboard.groups.forEach(({ group }) =>  {
        ids.push(group.id)
        group.widgets.forEach(({ widget }) => {
          ids.push(widget.id)
        })
      })

      await client.mutate({
        mutation: DELETE_OBJECTS,
        variables: {
          input: {
            mnPatch: ids.map(id => ({ id }))
          }
        },
      });

      dashboardsLazyQuery().then(({ data }) => {
        toast.dismiss(toastId);
        toast.success(msg.deleteDashboardModal.deleted);
        if (matchBoard?.params.dashboardId === props.dashboardId) {
          if (data.dashboards.length) {
            history(`/boards/${data.dashboards[0].id}`);
          } else {
            history(`/boards`);
          }
        }
        submit();
      });
    } catch (err) {
      toast.error(err.toString(), { position: "bottom-center" });
    }
  };

  return (
    <>
      <ConfirmationModal
        modalOpen={props.isOpen}
        title={msg.deleteGroupModal.deleteGroup}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>
              {msg.deleteDashboardModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              data-delete-dashboard
              onClick={handleDeleteDashboard}
            >
              {msg.deleteDashboardModal.buttonDelete}
            </Button>
          </>
        }
      >
        {`Delete "${props.name}"?`}
      </ConfirmationModal>
    </>
  );
};

export default create(DeleteDashboardModal);
